import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import {filebasepath} from "../../Function";
import { Helmet } from 'react-helmet';
const Projectpage = ({projectdata,metadescription}) => {
  const[storematatag,setStorematatag] = useState("");
  const scrollToTop = () => {
    window.scrollTo(0, 0)
  }

  const Shortdesc = ({desc}) => {
    const tempElement = document.createElement('div');
    tempElement.innerHTML = desc;
    const plainText = tempElement.textContent;
    const shortDESC = plainText.substring(0, 50);
    return (
        <span dangerouslySetInnerHTML={{ __html: shortDESC }}></span>
      );
    
  };
  useEffect(()=>{
    const targetSlug = 'our-complete-projects';
    const targetObject = metadescription.find(item => item.slug === targetSlug);
    setStorematatag(targetObject)
   },[metadescription])

  return (
    <>
    {storematatag  &&
      <Helmet>
        <meta name="description" content={storematatag.meta_key_discription} />
        <meta name="keywords" content={storematatag.meta_keyword} />
      </Helmet>}
      <section className="project_gallery_for_projects">
        <div class="row justify-content-center">
          <div class="col-md-8 col-lg-5 text-center mb-3 mb-lg-4">
            <h5 class="text-danger">Projects</h5>
            <h2>Our Completed Projects</h2>
          </div>
        </div>
      </section>

     <section className="project_section_second">
       <div className="container-fluid">
        <div className="px-lg-5">
          <div className="row">
            {projectdata.map((item,index)=>{
               return(
                <div className="col-xl-3 col-lg-4 col-md-6 mb-4">
                <Link to={`/projects/${item.slug}`} className="project_single_page_link_show" onClick={scrollToTop}>
                <div className="bg-white rounded shadow-sm box_height_set_to_top">
                  <div className="image_wrape_for_project">
                  {item.image ? 
                <img
                  src={filebasepath+ item.image}
                  alt=""
                  className="img-fluid card-img-top image_warpebar_for_project"
                />:""}
                  </div>
                <div className="p-4">
                  <h5 className="project_title_for_project">{item.title}</h5>
                  <p className="project_para_for_project">
                   <Shortdesc desc={item.discription}  />
                  </p>
                
                </div>
              </div>
                </Link>
        
            </div>
               )
            })} 
       
      
          </div>
    
        </div>
       </div>
     </section>
    </>
  );
};

export default Projectpage;
