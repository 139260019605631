import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { apibaseURlweb,filebasepath } from "../../Function";
import axios from "axios";
import { ColorRing } from "react-loader-spinner";
import { Helmet } from 'react-helmet';


const ProjectSinglepage = () => {
  const [data, setData] = useState({});
  const [loading, setLoading] = useState(true);
  const { slug } = useParams();
  const ShowProjectdata = async () => {
    try {
      const responce = await axios.get(`${apibaseURlweb}project/${slug}`);
      if (responce.status === 200) {
        if (responce?.data?.status === true) {
          setData(responce.data.data);
          setLoading(false);
        }
      }
    } catch (error) {
 
    }
  };

  useEffect(() => {
    ShowProjectdata();
  }, []);

  return (
    <>
    {data  &&
      <Helmet>
        <meta name="description" content={data.meta_key_discription} />
        <meta name="keywords" content={data.meta_keyword} />
      </Helmet>}
      {loading ? (
        <div
          className="loader_spinner_css_apply_for_single_service"
          style={{ height: "70vh" }}
        >
          <ColorRing
            visible={true}
            height="80"
            width="80"
            ariaLabel="blocks-loading"
            wrapperStyle={{}}
            wrapperClass="blocks-wrapper"
            colors={["#313391", "#313391", "#313391", "#313391", "#313391"]}
          />
        </div>
      ) : (
        <>

          <section className="project_Single_page mt-5">
            <div className="container justify-content-center d-flex align-item-center">
              <div className="col-lg-12 col-md-12 col-12">
                <div className="our_single_page_design">
                 <h2> {data.title}</h2>
                </div>
                <div className="card border-0 bg-white">
                  {data.image ? 
                    <img  src={filebasepath + data.image} className="project_single_image" alt="..." />
                   :""}
                  <div className="card-body">
                  {/* <h5 className="project_title_for_project_for_single_page"></h5> */}
                    <p className="card-text">
                    <span dangerouslySetInnerHTML={{ __html: data.discription }} ></span>
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </>
      )}
    </>
  );
};

export default ProjectSinglepage;
