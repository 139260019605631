import React, { useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router';
import { apibaseURl, showErrorToast, showSuccessToast} from "../../../Function";
import axios from 'axios';
const Seoupdate = () => {

    const { id } = useParams();
    const navigate = useNavigate();
    const [state, setState] = useState({
        meta_keyword:"",
        meta_key_discription:""
      });

    //   single  seo show  =============>
    const handleFetseo = async () => {
        try {
          const response = await axios.get(`${apibaseURl}seo/${id}`);
          if (response.status === 200 && response.data.status === true) {
            setState(response.data.data);
          }
        } catch (error) {
          console.error("An error occurred:", error);
        }
      };
      useEffect(() => {
        handleFetseo();
      }, [id]);


    //   update seomata ===================>
    const handleUpdatesubmit = async (e) => {
        e.preventDefault();
        const userdata = {
          meta_keyword:state.meta_keyword,
          meta_key_discription:state.meta_key_discription,
          id: id
        };
 
        try {
          const response = await axios.post(`${apibaseURl}editseo`, userdata);
  
          if (response.status === 200 && response.data.status === true) {
            showSuccessToast(response.data.msg);
            navigate("/admin/seo-mata");
          } else {
            showErrorToast(response.data.error);
          }
        } catch (error) {
          // console.error("An error occurred:", error);
        }
      };
      const handleInputChange = (e) => {
        const { name, value } = e.target;
        setState({ ...state, [name]: value });
      };
    


  return (
    <>
      <div class="container-xxl flex-grow-1 container-p-y">
        <div class="row">
          <div class="col-md-12">
            <div class="card mb-4">
              <h5 className="card-header">SEO Meta</h5>
               <div className="card-body">
                    <form onSubmit={handleUpdatesubmit}>
                       <div className='border_warepe_seo_scs mb-3 mb-lg-4'>
                          <h3>{state.title}</h3>
                          <div className="row">

                        <div className="form-group col-md-12 mb-3">
                          <label className="form-label">META KEYWORD</label>
                          <input
                            type="text"
                            className="form-control"
                            placeholder="Meta Keyword"
                            name="meta_keyword"
                            value={state.meta_keyword}
                            onChange={handleInputChange}
                          />
                        </div>

                        <div className="form-group col-md-12 mb-3">
                          <label for="exampleFormControlTextarea1" className="form-label">META DESCRIPTION</label>
                          <textarea class="form-control" id="exampleFormControlTextarea1" rows="2"
                            name="meta_key_discription"
                            placeholder="Meta description"
                            value={state.meta_key_discription}
                            onChange={handleInputChange}
                            ></textarea>
                        </div>
                    
                         </div>
                       </div>
                       <div className="form-group col-md-12 mb-3 text-center">
                          <button className="btn btn-primary" type="submit">Submit</button>
                        </div>
                    </form>
                </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default Seoupdate;
