import React from 'react'
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import moment from "moment";




const Modeldata = (props) => {
  
  return (
    <>
      <Modal
        show={props.show}
        onHide={() => props.handleShow(false)}
        backdrop="static"
        keyboard={false}
        centered
        size="lg"
      >
        <Modal.Header closeButton>
          <Modal.Title>Enquiry Information<span className={`btn btn-sm rounded-pill  ${props.modeldata.status == 'Read' ? "btn-primary" : "btn-danger"}`}>{props.modeldata.status}</span></Modal.Title>
        </Modal.Header>
        <Modal.Body>
        <ul>
          <li>{moment(props.modeldata.created).format('MMMM Do YYYY, h:mm:ss a')}</li>
          <li>{props.modeldata.name}</li>
          <li>{props.modeldata.email}</li>
          <li>{props.modeldata.message}</li>
        </ul>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={() => props.handleShow(false)}>
            Close
          </Button>
       
        </Modal.Footer>
      </Modal>
    </>
  );


}

export default Modeldata;
