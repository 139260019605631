import React from 'react'
import { Link } from 'react-router-dom';
const Footer = () => {
  return (
    <>
        <footer className="content-footer footer bg-footer-theme">
          <div className="container-xxl d-flex flex-wrap justify-content-between py-2 flex-md-row flex-column">
            <div className="mb-2 mb-md-0">
              Design & Developed by 
              <a
                href="https://softechplanet.com/"
                target="_blank"
                className="footer-link fw-bolder">
                 &nbsp;
                 Softech Planet
              </a>
            </div>
          </div>
        </footer>
    </>
  )
}

export default Footer;
