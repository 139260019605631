import React, { useEffect, useState } from "react";
import { apibaseURl } from "../../../Function";
import axios from "axios";
import Swal from "sweetalert2";
import { ColorRing } from "react-loader-spinner";
import moment from "moment";

const SubscribesEmail = () => {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);
  // show deta ===========================================>
  const showSubscribersEmaildata = async () => {
    try {
      const responce = await axios.get(`${apibaseURl}subscribe`);
      if (responce.status === 200) {
        if (responce?.data?.status === true) {
          setData(responce.data.data);
          setLoading(false);
        }
      }
    } catch (error) {}
  };

  useEffect(() => {
    showSubscribersEmaildata();
  }, []);

  // delete api call ========================================================>
  const handledelete = (id) => {
    Swal.fire({
      title: "Are you sure?",
      text: "You won't be able to revert this!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete it!",
    }).then((result) => {
      if (result.isConfirmed) {
        const connectapi = deleteApiCall(id);
        connectapi.then((responses) => {
          if (responses.status == 200) {
            if (responses?.data?.status == true) {
              Swal.fire("Deleted!", "Your file has been deleted.", "success");
              showSubscribersEmaildata();
            } else {
              Swal.fire(
                "Opps Something Wrong?",
                "That thing is still around?",
                "question"
              );
            }
          }
        });
      }
    });
  };

  const deleteApiCall = async (id) => {
    const responses = await axios.delete(`${apibaseURl}deletesubscribe/${id}`);
    return responses;
  };

  return (
    <>
      <div>
        <div class="container-xxl flex-grow-1 container-p-y">
          <div className="card">
            <h5 className="card-header">Subscribe Emails</h5>

            {loading ? (
              <div className="loader_spinner_css_apply">
                <ColorRing
                  visible={true}
                  height="80"
                  width="80"
                  ariaLabel="blocks-loading"
                  wrapperStyle={{}}
                  wrapperClass="blocks-wrapper"
                  colors={[
                    "#313391",
                    "#313391",
                    "#313391",
                    "#313391",
                    "#313391",
                  ]}
                />
              </div>
            ) : (
              <div className="table-responsive text-nowrap">
                <table className="table">
                  <thead className="table-light">
                    <tr>
                      <th>S.No.</th>
                      <th>Email</th>
                      <th>craeted</th>
                      <th>Actions</th>
                    </tr>
                  </thead>
                  <tbody className="table-border-bottom-0">
                    {data.length > 0 ? (
                      data.map((item, index) => {
                        return (
                          <tr>
                            <td>{index + 1}</td>
                            <td>{item.email}</td>
                            <td>
                              <td>
                                {moment(item.created).format(
                                  "MMMM Do YYYY, h:mm:ss a"
                                )}
                              </td>
                            </td>
                            <td>
                              <div className="action_button_preform">
                                <button
                                  type="button"
                                  class="btn btn-icon btn-danger link_decode"
                                  onClick={() => handledelete(item.id)}
                                >
                                  <span class="tf-icons">
                                    <i
                                      class="fa fa-trash"
                                      aria-hidden="true"
                                    ></i>
                                  </span>
                                </button>
                              </div>
                            </td>
                          </tr>
                        );
                      })
                    ) : (
                      <tr>
                        <td className="md_center" colSpan={9}>
                          No data Found
                        </td>
                      </tr>
                    )}
                  </tbody>
                </table>
              </div>
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default SubscribesEmail;
