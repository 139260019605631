import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
const ScrolltoTop = () => {
  const location = useLocation();
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    window.scrollTo(0, 0);
    setLoading(true);
    const fetchData = async () => {
      setTimeout(() => {
        setLoading(false);
      }, 500);
      
    };

    fetchData();
  }, [location.pathname]);

  return (
    <>

    </>
  );
};

export default ScrolltoTop;