


import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom';
import { BiPlusMedical } from "react-icons/bi";
import { apibaseURl,filebasepath } from '../../../Function';
import axios from 'axios';
import Swal from 'sweetalert2'
import { ColorRing } from 'react-loader-spinner'


const Seo = () => {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);
  // deta fech show ==============================>
  const showservicedata = async () => {
    try {
      const responce = await axios.get(`${apibaseURl}seo`);
 
      if (responce.status === 200) {
        if (responce?.data?.status === true) {
          setData(responce.data.data);
          setLoading(false)
   
        }
      }
    } catch (error) {
 
    }
  };

  useEffect(() => {
    showservicedata();
  }, []);

  // deta update status ==============================>


  return (

    <>
    
       <div class="container-xxl flex-grow-1 container-p-y">
         <div className="card">
         <>
         <div className="data_acrd_wrapper">
            <h5 className="card-header">Seo Meta</h5>
            {/* <Link class="btn btn-primary primary_ya" to="addservice"><span className='class_plus'><BiPlusMedical/></span>Add Service</Link> */}
          </div>
            {loading ?
             <div className='loader_spinner_css_apply'>
                          <ColorRing
                          visible={true}
                          height="80"
                          width="80"
                          ariaLabel="blocks-loading"
                          wrapperStyle={{}}
                          wrapperClass="blocks-wrapper"
                          colors={['#313391', '#313391', '#313391', '#313391', '#313391']}
                        />
            </div>
             :
          <div className="table-responsive text-nowrap">
          
            <table className="table">
            <thead className="table-light">
                <tr>
                 <th>S.No.</th>
                 <th>title</th>
                 <th>Slug</th>
                 <th>Meta Keyword</th>
                 <th>Meta Description</th>
                 <th>Status</th>
                 <th>Actions</th>

                </tr>
            </thead>
            <tbody className="table-border-bottom-0">
            {data.length >0 ? data.map((item,index)=>{
              return(
                <tr>
                <td>{index+1}</td>
                <td>{item.title}</td>
                <td>{item.slug}</td>
                <td>{item.meta_keyword}</td>
                <td>{item.meta_key_discription}</td>
              
                <td><button type="button" class={`btn btn-sm rounded-pill  ${item.status == 'active' ? "btn-primary" : "btn-danger"}`}>{item.status == 'active' ? "Active" : "Inactive"}</button></td>

                <td>
                    <div className='action_button_preform'>
                     <Link class="btn btn-icon btn-primary link_decode" to={`seo_edit/${item.id}`}>
                        <span class="tf-icons"><i class="fa fa-pencil" aria-hidden="true"></i></span>
                     </Link>
                    </div>
                </td>
                </tr>
              )
            }):
            <tr><td className='md_center' colSpan={9}>No data Found</td></tr>
            } </tbody>
            </table>
        </div>

      }
    
        </>
         </div>
       </div>
    </>
  )
}

export default Seo;





