import React, { useContext } from 'react'
import { Link } from 'react-router-dom';
import userimg from '../../../admin/assest/img/avatars/10.png'
import { UserContext } from '../../../App';
const Header = ({handleClick}) => {
  const userdatacontext=useContext(UserContext)
  return (
    <>
<nav className="layout-navbar container-xxl navbar navbar-expand-xl navbar-detached align-items-center bg-navbar-theme"
     id="layout-navbar" >
      <div className="layout-menu-toggle navbar-nav align-items-xl-center me-3 me-xl-0 d-xl-none">
          <Link  className="nav-item nav-link px-0 me-xl-4" to="#" onClick={handleClick} >
            <i class="fa fa-bars" aria-hidden="true"></i>
          </Link>
        </div>
        <div
          className="navbar-nav-right d-flex align-items-center" id="navbar-collapse">
          <div className="navbar-nav align-items-center">
            <div className="nav-item d-flex align-items-center">
            <i class="fa fa-bars d_none_bars" aria-hidden="true"></i>
                <Link to="/" className='goto_web' target="_blank">Go To Website</Link>
            </div>
          </div>
          <ul className="navbar-nav flex-row align-items-center ms-auto">
            <li className="nav-item navbar-dropdown dropdown-user dropdown">
              <Link className="nav-link dropdown-toggle hide-arrow" to="#" data-bs-toggle="dropdown" >
                <div className="avatar avatar-online">
                  <img src={userimg} alt="" className="w-px-40 h-auto rounded-circle"/>
                </div>
              </Link>
              <ul className="dropdown-menu dropdown-menu-end">
                <li>
                  <Link className="dropdown-item" to="#">
                    <div className="d-flex">
                      <div className="flex-shrink-0 me-3">
                        <div className="avatar avatar-online">
                          <img
                            src={userimg}
                            alt=""
                            className="w-px-40 h-auto rounded-circle"
                          />
                        </div>
                      </div>
                      <div className="flex-grow-1">
                        <span className="fw-semibold d-block">Administrator</span>
                        <small className="text-muted">Admin</small>
                      </div>
                    </div>
                  </Link>
                </li>
                <li>
                  <div className="dropdown-divider" />
                </li>
                <li>
                  <Link className="dropdown-item" to="profile">
                  <i class="fa fa-user-o decom_dem" aria-hidden="true"></i>
                    <span className="align-middle">My Profile</span>
                  </Link>
                </li>
                <li>
                  <Link className="dropdown-item" to="setting">
                  <i class="fa fa-cog decom_dem" aria-hidden="true"></i>
                    <span className="align-middle">Settings</span>
                  </Link>
                </li>
                <li>
                  <button className="dropdown-item" onClick={()=>userdatacontext.logout()} >
                   <i class="fa fa-sign-out decom_dem" aria-hidden="true"></i>
                    <span className="align-middle">Log Out</span>
                  </button>
                </li>
              </ul>
            </li>
          </ul>
        </div>
      </nav>
    </>
  )
}

export default Header;
